import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user',class:"col-6",label:'Survey', sortable: true },
    
    { key: 'attendees',class:"col-5", label:'Attendees', sortable: false },
   
    { key: 'actions',class:"col-1", label:'Actions', sortable: false },
   
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchCourses = (ctx, callback) => {

     var surveyid=  router.currentRoute.query.s; ///router.currentRoute.params.id;
     console.log(" fetch courses triggered surveyid:"+ surveyid);
    store
      .dispatch('app-surveys/fetchSubjects', {
        id:   surveyid,
        q: searchQuery.value,
        l: perPage.value,
        p:currentPage.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {

       
        const { data ,allresults  } = response.data
           console.log("resolved Data");;
           console.log(response.data);
           totalUsers.value = allresults;
        callback(data)
       
        
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const surveyInfo = ref(null)

  const fetchSurvey = (ctx, callback) => {

    var surveyid=  router.currentRoute.query.s; ///router.currentRoute.params.id;
    console.log(" fetch courses triggered surveyid:"+ surveyid);
   store
     .dispatch('app-surveys/fetchSurvey', {
       id:   surveyid,
       q: searchQuery.value,
       l: perPage.value,
       p:currentPage.value,
       perPage: perPage.value,
       page: currentPage.value,
       sortBy: sortBy.value,
       sortDesc: isSortDirDesc.value,
       role: roleFilter.value,
       plan: planFilter.value,
       status: statusFilter.value,
     })
     .then(response => {

      console.log('incoming survey info');
       const { data ,allresults  } = response.data
          console.log("resolved survey info Data");;
          console.log(response.data);
          surveyInfo.value=response.data.data;
 
      
       
     })
     .catch((ext) => {
       toast({
         component: ToastificationContent,
         props: {
           title: 'Error fetching courses list',
           icon: 'AlertTriangleIcon',
           variant: 'danger',
           text:ext
         },
       })
     })
 }
 fetchSurvey();


  const draftCourse = (ctx, callback) => {
    store
      .dispatch('app-surveys/draftCourse', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {

       
        const { data  } = response.data
          
        callback(data)
       
        
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating course status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    console.log("status "+status);
    if (status ===0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }
  const resolveUserStatusVariantText = status => {
    if (status ===400) return 'deleted'
    if (status === 1) return 'published'
    if (status === 0) return 'draft'
    return 'primary'
  }

  const resolvePageroute = coursemode => {
    if (coursemode === 'IN-PERSON') return 'course-sessions'
    if (coursemode === 'ONLINE') return 'course-lessons'
    if (coursemode === 'HYBRID') return 'course-lessons'
   
    return 'course-lessons'
  }
  const resolveCourseContent= coursemode => {
    if (coursemode === 'IN-PERSON') return 'Sessions'
    if (coursemode === 'ONLINE') return 'Lectures'
    if (coursemode === 'HYBRID') return 'Lectures'
   
    return 'Lectures'
  }


  const resolveSurveyStatus = status => {
    if (status === 0) return 'draft'
    if (status === 50) return 'ongoing'
    if (status === 100) return 'completed'
    if (status === 200) return 'canceled'
    if (status === 300) return 'terminated'
    return 'unknown'
  }
  const resolveSurveyStatusVariant = status => {
    console.log("status "+status);
    if (status ===0) return 'light-secondary'
    if (status === 50) return 'light-warning'
    if (status === 100) return 'light-success'
    if (status === 200) return 'light-dark'
    if (status === 300) return 'light-dark'

    return 'primary'
  }


  return {
    surveyInfo,
    fetchSurvey,
    fetchCourses,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusVariantText,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    resolvePageroute,
    resolveCourseContent,
    resolveSurveyStatus,
    resolveSurveyStatusVariant
  }
}
